import React from "react"
import { Col, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import SocialSharing from "../components/SocialSharing/SocialSharing"
import PackagesWidget from "../components/PackagesWidget/PackagesWidget"

const description = `Enjoy many amenities at The Residences at Biltmore all suite hotel from 24 hour concierge services to our amazing outdoor pool, hot tub, roaring fire-pit and fitness center you're sure to enjoy it all!`

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    marginBottom: 32,
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: 1360,
  },
}

const colProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  style: {
    paddingTop: 32,
    paddingBottom: 32,
  },
}

export default ({ data, location }) => {
  const { hero } = data

  const heroProps = {
    contentfulAsset: hero,
    height: `30vh`,
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Amenities & Lodging in Asheville - The Residences at Biltmore"
        url={location.href}
      />
      <Hero {...heroProps} />
      <BookingBar />
      <Row {...rowProps}>
        <Col {...colProps}>
          <SeoHeader
            seo={{ level: 1, content: `Amenities` }}
            human={{
              level: 2,
              content: `Everything you need. Right where you want it.`,
            }}
          />
          <Typography.Paragraph style={{ marginTop: 48 }}>
            Luxury, comfort, and tranquility are synonymous with The Residences
            at Biltmore all-suite hotel. Set within a beautifully landscaped
            terrace, our outdoor pool and hot tub — both of which are
            open seasonally, typically between mid-May and mid-September,
            weather permitting — and soothing waterfall feature provide the
            perfect opportunity for exercise, fun, and relaxation.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Our brand new on-site restaurant, bar, and lounge allow our guests
            to park the car and start enjoying the mountains as soon as they
            arrive.
          </Typography.Paragraph>
          <Typography.Paragraph>
            As night falls, harness the warmth and light of an evening fire by
            gathering at our outdoor fire pit. Intimate and charming, The
            Residences at Biltmore poolside experience is a relaxing retreat for
            every guest.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Guests will enjoy the many amenities found at The Residences at
            Biltmore, including:
          </Typography.Paragraph>
          <Typography.Paragraph>
            <ul>
              <li>Our brand new on-site restaurant, bar, and lounge</li>
              <li>Outdoor pool and hot tub (open seasonally)</li>
              <li>Event spaces that accommodate up to 100 guests</li>
              <li>Fire pit lit at dusk every evening</li>
              <li>24/7 Concierge service</li>
              <li>Complimentary Wifi</li>
              <li>
                Fitness facility complete with treadmills, elliptical trainer,
                recumbent bike, free-weights, and pin-loaded resistance
                equipment
              </li>
            </ul>
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Col span={23}>
          <SocialSharing pageUrl={location.href} />
        </Col>
      </Row>
      <PackagesWidget />
    </Shell>
  )
}

export const query = graphql`
  query {
    ...AllHotelAmenities
    hero: contentfulAsset(title: { eq: "RAB - Pool 1" }) {
      ...HeroFluid
    }
  }
`
